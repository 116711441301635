import React, { Component } from 'react';
import {Spinner, Card, Image } from 'react-bootstrap';
import { API } from '../variables/variables'; 
import Cookies from 'js-cookie';

class PetGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pets: [],
            petTypes: [],
            loading: true
        }
        this.goToPage = this.goToPage.bind(this);
    }

    async componentDidMount() {
        let response = await fetch(API + '/types', {
            method: 'GET',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
              }
        });
        
        if(response.ok){
            let data = await response.json();
            this.setState({petTypes : data.data});
            this.setState({petType : data.data[0].type});
        }
        this.setState({loading : false});
    }


    goToPage(type) {
        window.location.href = "/animal/"+type;
    }


    render() {
        return (
            <div>
                {this.state.loading? 
                    <div className="center"><Spinner /></div>
                    :
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                        {this.state.petTypes.map((pet, index) =>
                            <Card className="pet-card" key={index} style={{ width: '300px' }} onClick={() => this.goToPage(pet.type)}>
                                <Card.Header style={{ backgroundColor: '#fea910' }}>
                                    <div className="center">
                                        <b>{pet.type}s</b>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="center">
                                        <Image src={pet.picture_url} width="250px" height="250px"/>
                                    </div>
                                    <ul>
                                        <li><b>Total: </b> {pet.pet_count}</li>
                                        <li><b>Published: </b> {pet.published_count}</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        )}
                    </div>
                }
            </div>
        )
    }
}

export default PetGallery;