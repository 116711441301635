import React, { Component } from 'react';
import { Form} from 'react-bootstrap';

class DifficultySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            difficulty: this.props.difficulty,
            dif_cost: this.props.dif_cost,
            dif_knowledge: this.props.dif_knowledge,
            dif_maintenance: this.props.dif_maintenance,
            dif_time: this.props.dif_time
        }
        this.updateDifficultyRating = this.updateDifficultyRating.bind(this);
        this.updateDifCost = this.updateDifCost.bind(this);
        this.updateDifTime = this.updateDifTime.bind(this);
        this.updateDifMaintenance = this.updateDifMaintenance.bind(this);
        this.updateDifKnowledge = this.updateDifKnowledge.bind(this);
    }

    updateDifficultyRating(value) {
        this.setState({difficulty : value}, () => {
            this.props.updateDifficulty(value);
        });
    }

    updateDifCost(value) {
        this.setState({dif_cost: value}, () => {
            this.props.updateDifCost(value);
        });
    }

    updateDifTime(value) {
        this.setState({dif_time : value}, () => {
            this.props.updateDifTime(value);
        });
    }

    updateDifMaintenance(value) {
        this.setState({dif_maintenance : value}, () => {
            this.props.updateDifMaintenance(value);
        });
    }

    updateDifKnowledge(value) {
        this.setState({dif_knowledge : value}, () => {
            this.props.updateDifKnowledge(value);
        });
    }

    render() {
        return ( 
            <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                {/*Pets difficulty rating*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Difficulty Rating *</b></Form.Label>
                    <Form.Select aria-label="Default select example" onChange={e => this.updateDifficultyRating(e.target.value)} value={this.state.difficulty}>
                        <option vlaue="Very Easy">Very Easy</option>
                        <option value="Easy">Easy</option>
                        <option value="Medium">Medium</option>
                        <option value="Hard">Hard</option>
                    </Form.Select>
                </Form.Group>
                {/*Pets difficulty items*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Time Commitment *</b></Form.Label>
                    <textarea rows="2" 
                        className="requirement-text-area form-control" 
                        placeholder="Enter the time commitment given by chatGPT from Query 1"
                        onChange={e => this.updateDifTime(e.target.value)}
                        value={this.state.dif_time}
                        spellCheck={true}
                    ></textarea>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><b>Knowledge Required *</b></Form.Label>
                    <textarea rows="2" 
                        className="requirement-text-area form-control" 
                        placeholder="Enter knowledge required given by chatGPT from Query 1"
                        onChange={e => this.updateDifKnowledge(e.target.value)}
                        value={this.state.dif_knowledge}
                        spellCheck={true}
                    ></textarea>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><b>Cost of Care *</b></Form.Label>
                    <textarea rows="2" 
                        className="requirement-text-area form-control" 
                        placeholder="Enter the cost of care given by chatGPT from Query 1"
                        onChange={e => this.updateDifCost(e.target.value)}
                        value={this.state.dif_cost}
                        spellCheck={true}
                    ></textarea>
                    
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><b>Maintenance Needs *</b></Form.Label>
                    <textarea rows="2" 
                        className="requirement-text-area form-control" 
                        placeholder="Enter the maintenance needs given by chatGPT from Query 1"
                        onChange={e => this.updateDifMaintenance(e.target.value)}
                        value={this.state.dif_maintenance}
                        spellCheck={true}
                    ></textarea>
                </Form.Group>
            </Form>
        )
    }
}

export default DifficultySection;