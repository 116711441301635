import React, { Component } from 'react';
import { Container, Image, NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.png';
import Cookies from 'js-cookie';

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.logout = this.logout.bind(this);
    }

    logout() {
        Cookies.set('pqsToken', '', { expires: 7 });
        window.location.href='/';
    }
    

    render() {
        return (
            <div>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand href="/"><Image src={logo} width="50px" height="50px"/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav>
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/assignments">Assignments</Nav.Link>
                                <Nav.Link href="/articles">Articles</Nav.Link>
                                <NavDropdown title="Actions" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/edit/-1">Add a New Pet</NavDropdown.Item>
                                    <NavDropdown.Item href="/create-assignment">Create Assignment</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/articles/-1">Create a New Article</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={this.logout}><span className="red-text">Logout</span></NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                    
            </div>
        )
    }
}

export default NavigationBar;