import React, { Component } from 'react';
import PackageComponent from './PackageComponents/PackageComponent';


class PackagesSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            essentials: this.props.essentials,
            intermediate: this.props.intermediate,
            advanced: this.props.advanced
        }
    }

    render() {
        return (
            <div className="inside-component">
                <div>
                    <h5 className="form-section-name-inner"><b>Essentials Package *</b></h5>
                    <div className="form-section">
                        <PackageComponent 
                            title={"Essentials"}
                            urls={this.state.essentials}
                            updateParent={this.props.updateEssentials}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Intermediate Package *</b></h5>
                    <div className="form-section">
                        <PackageComponent 
                            title={"Intermediate"}
                            urls={this.state.intermediate}
                            updateParent={this.props.updateIntermediate}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Advanced Package *</b></h5>
                    <div className="form-section">
                        <PackageComponent 
                            title={"Advanced"}
                            urls={this.state.advanced}
                            updateParent={this.props.updateAdvanced}/>
                    </div>
                    <br></br>
                </div>
            </div>
        )
    }
}

export default PackagesSection;