import React, { Component } from 'react';
import { Form, Image} from 'react-bootstrap';
import { API } from '../../../variables/variables';
import Cookies from 'js-cookie';

class OverviewSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            petName: this.props.petName,
            petType: this.props.petType,
            profilePicture: this.props.profilePicture,
            currentProfilePicture: this.props.currentProfilePicture,
            petTypes: []
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.updatePetName = this.updatePetName.bind(this);
        this.updatePetType = this.updatePetType.bind(this);
    }

    async componentDidMount() {
        let response = await fetch(API + '/types', {
            method: 'GET',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
              }
        });
        
        if(response.ok){
            let data = await response.json();
            this.setState({petTypes : data.data});
            if(this.props.petType === null){
                this.setState({petType : data.data[0].type}, () => {
                    this.props.updatePetType(data.data[0].type);
                });
            }
        }
    }


    onFileChange(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        this.setState({profilePicture: e.target.files[0]}, () => {
            this.props.updateProfilePicture(e.target.files[0]);
        });

        reader.onloadend = () => {
            // Update the state with the selected file
            this.setState({ previewImg: reader.result });
        };
    
        if (file) {
            // Read file as data URL
            reader.readAsDataURL(file);
        }
        else{
            this.setState({ previewImg: null });
        }
    }

    updatePetName(value) {
        this.setState({petName : value}, () => {
            this.props.updatePetName(value);
        });
    }

    updatePetType(value) {
        this.setState({petType : value}, () => {
            this.props.updatePetType(value);
        })
    }

    render() {
        return ( 
            <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                {/*Pets name*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Pet Name *</b></Form.Label>
                    <Form.Control type="text" placeholder="Enter pet name" onChange={e => this.updatePetName(e.target.value)} value={this.state.petName}/>
                    <Form.Text className="text-muted">
                        This is the name of the pet.
                    </Form.Text>
                </Form.Group>

                {/*The pet type*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Pet Type *</b></Form.Label>
                    <Form.Select aria-label="Default select example" onChange={e => this.updatePetType(e.target.value)} value={this.state.petType}>
                        {this.state.petTypes.map((type, index) => 
                            <option vlaue={type.type} key={index}>{type.type}</option>
                        )}
                    </Form.Select>
                </Form.Group>

                {/*Pets profile picture*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Pet Profile Picture *</b></Form.Label>
                    <br></br>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={e => this.onFileChange(e)}
                    />
                    <br></br>
                    <br></br>
                    <Image src={this.state.previewImg} rounded width="300px" height="300px"/>
                    <br></br>
                    <b>Current Picture</b>
                    <br></br>
                    {this.state.currentProfilePicture !== null? <><br></br><Image src={this.state.currentProfilePicture} rounded width="300px" height="300px"/></> : <>This pet currently has no picture</>}
                </Form.Group>
            </Form>
        )
    }
}

export default OverviewSection;