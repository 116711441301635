import React, { Component } from 'react';
import {Spinner, Card, Image, Container, Button, Form, Modal } from 'react-bootstrap';
import { API } from '../variables/variables'; 
import Cookies from 'js-cookie';
import NavigationBar from '../../NavigationBar';

class CreateAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignments: [],
            petTypes: [],
            loading: true,
            petType: "",
            loggedOut: false,
            petName: "",
            showSuccessModal: false,
            showErrorModal: false,
            errorMessage: "",
            admins: ["drew", "ares", "shawn", "ferg", "random"],
            selectedAdmin: "drew"
        }
        this.createAssignment = this.createAssignment.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleErrorClose = this.handleErrorClose.bind(this);
    }

    async componentDidMount() {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
            body: JSON.stringify({})
        };
        const response = await fetch(API + '/authenticate', requestOptions);
        const data = await response.json();
        if(response.ok){
            if(data.Auth === true){
                let types_response = await fetch(API + '/types', {
                    method: 'GET',
                    headers: {
                        'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
                      }
                });
                
                if(types_response.ok){
                    let data = await types_response.json();
                    this.setState({petType : data.data[0].type})
                    this.setState({petTypes : data.data});
                }
                this.setState({loggedIn : true});
            }
        }
        this.setState({loading : false});
    }

    async createAssignment() {
        this.setState({errorMessage : ""});
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
            body: JSON.stringify({pet_name : this.state.petName, pet_type : this.state.petType, admin: this.state.selectedAdmin})
        };
        const response = await fetch(API + '/assignments', requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({showSuccessModal : true});
        }
        else{
            this.setState({errorMessage : data.reason});
            this.setState({showErrorModal : true});
        }
    }

    handleClose() {
        this.setState({showSuccessModal : false});
        this.setState({petName : ""});
    }

    handleErrorClose() {
        this.setState({showErrorModal : false});
    }

    render() {
        return (
            <div>
                {this.state.loading? 
                    <div className="center"><Spinner /></div>
                    :
                    <div>
                        {this.state.loggedOut? 
                            <div className="center">
                                <h1 className="center">You've been logged out</h1>
                                <Button className="center" href="/">To Login</Button>
                            </div>
                            :
                            <div>
                                <NavigationBar />
                                <Container>
                                    <h1>Create Assignment</h1>
                                    <h4>Assignments are randomly assigned when created.</h4>
                                    <br></br>
                                    <Card className="new-assignment-card">
                                        <Card.Header><h3>New Assignment</h3></Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Pet Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Pet Name" onChange={e => this.setState({petName : e.target.value})} value={this.state.petName}/>
                                                    <Form.Text className="text-muted">
                                                    The name of the pet
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Pet Type</Form.Label>
                                                    <Form.Select aria-label="Default select example" onChange={e => this.setState({petType : e.target.value})} value={this.state.petType}>
                                                        {this.state.petTypes.map((type, index) => 
                                                            <option vlaue={type.type} key={index}>{type.type}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Text className="text-muted">
                                                    The type of pet
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Assignee</Form.Label>
                                                    <Form.Select aria-label="Default select example" onChange={e => this.setState({selectedAdmin : e.target.value})} value={this.state.selectedAdmin}>
                                                        {this.state.admins.map((admin, index) => 
                                                            <option vlaue={admin} key={index}>{admin}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Text className="text-muted">
                                                    The researcher
                                                    </Form.Text>
                                                </Form.Group>
                                                <Button variant="dark"className="float-right" onClick={this.createAssignment}>
                                                    Create
                                                </Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Container>
                            </div>
                        }
                    </div>
                }
                <Modal show={this.state.showSuccessModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><span className="success-text">Assignment Created!</span></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>Your assignment has been created successfully</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showErrorModal} onHide={this.handleErrorClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><span className="error-text">Error</span></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>{this.state.errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleErrorClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default CreateAssignment;