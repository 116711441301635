import React, { Component } from 'react';
import {marked} from 'marked';
import { Button, Modal, Form, Card, Image, Container} from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import NavigationBar from '../../NavigationBar';
import { CiCircleList } from "react-icons/ci";
import { GoListOrdered } from "react-icons/go";
import { CiImageOn } from "react-icons/ci";
import { CiLink } from "react-icons/ci";
import { FaItalic } from "react-icons/fa";
import { FaBold } from "react-icons/fa6";
import { API } from '../variables/variables';
import Cookies from 'js-cookie';

class ArticlesEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
        title: '',
        titleMarkdown: '',
        markdown: '',
        showImageChooser: false,
        petTypes: [],
        selectedPetImages: [],
        id: this.props.id,
        showErrorMessage : false,
        errorMessage: "",
        showSaveMessage: false,
        showDeleteModal: false,
        showDeleteSuccess : false,
        showPublishSuccess: false,
        loggedOut: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getPetPictures = this.getPetPictures.bind(this);
    this.addImage = this.addImage.bind(this);
    this.openImageChooser = this.openImageChooser.bind(this);
    this.handleErrorMessageClose = this.handleErrorMessageClose.bind(this);
    this.saveArticle = this.saveArticle.bind(this);
    this.handleSaveMessageClose = this.handleSaveMessageClose.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDeleteSuccessClose = this.handleDeleteSuccessClose.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
    this.handlePublishClose = this.handlePublishClose.bind(this);
    this.publishArticle = this.publishArticle.bind(this);
  }

  async componentDidMount() {
    let response = await fetch(API + '/types', {
      method: 'GET',
      headers: {
          'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
        }
    });
    
    if(response.ok){
      let data = await response.json();
      this.setState({petTypes : data.data});
      this.setState({petType : data.data[0].type});
    }
    else{
      this.setState({loggedOut : true});
    }

    if(this.props.id !== "-1") {
      let article_response = await fetch(API + "/article?id=" + this.props.id, {
        method: 'GET',
        headers: {
          'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
        }
      });
      if(article_response.ok){
        let data = await article_response.json();
        this.setState({title : data.data.article_name});
        this.setState({titleMarkdown : "# " + data.data.article_name});
        this.setState({markdown : data.data.article_content});
      }
    }
    else{
      console.log("New Article");
    }
    
  }

  async getPetPictures(pets_type) {
    let requestOptions = {
      method: 'GET',
      headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
    };
    const response = await fetch(API + '/pets?type=' + pets_type , requestOptions);
    const data = await response.json();
    if(response.ok){
      this.setState({selectedPetImages : data.data});
    }
  }

  async saveArticle() {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
      body: JSON.stringify({id : this.state.id, name : this.state.title, content : this.state.markdown})
    };
    const response = await fetch(API + '/article', requestOptions);
    const data = await response.json();
    if(response.ok){
      this.setState({id : data.data});
      this.setState({showSaveMessage : true});
    }
    else if(response.status === 403){
      this.setState({errorMessage : data.Error});
      this.setState({showErrorMessage : true});
    }
    else if(response.status === 480){
      this.setState({errorMessage : data.error});
      this.setState({showErrorMessage : true});
    }
    else if(response.status === 500){
      this.setState({errorMessage : data.reason});
      this.setState({showErrorMessage : true});
    }
  }

  handleTitleChange(value) {
    this.setState({title : value});
    this.setState({titleMarkdown : "# " + value});
  }

  handleChange(event) {
    this.setState({ markdown: event.target.value });
  }

  handleClose() {
    this.setState({showImageChooser : false});
  }

  handleAddItem(itemType) {
    let newItemMarkdown = '';
    switch (itemType) {
      case 'heading 2':
        newItemMarkdown = '## New Heading\n\n';
        break;
      case 'heading 3':
        newItemMarkdown = '### New Heading\n\n';
        break;
      case 'heading 4':
        newItemMarkdown = '#### New Heading\n\n';
        break;
      case 'unordered list':
        newItemMarkdown = '- List item\n';
        break;
      case 'ordered list':
        newItemMarkdown = '1. List item\n';
        break;
      case 'link':
        newItemMarkdown = '[Link text](https://example.com)\n';
        break;
      case 'bold':
        newItemMarkdown = '**bold text**\n';
        break;
      case 'italicised':
        newItemMarkdown = '_italicised text_\n';
        break;
      // Add more cases for other item types as needed
      default:
        break;
    }
    this.setState(prevState => ({
      markdown: prevState.markdown + newItemMarkdown
    }));
  }
  
  handleKeyPress(event) {
    // If the tab key is pressed
    if (event.keyCode === 9) {
      event.preventDefault(); // Prevent the default tab behavior
      const { selectionStart, selectionEnd } = event.target;
      const { markdown } = this.state;

      // Insert tab character at the cursor position
      const updatedMarkdown =
        markdown.substring(0, selectionStart) +
        '\t' +
        markdown.substring(selectionEnd);

      // Update the state with the modified markdown
      this.setState({ markdown: updatedMarkdown }, () => {
        // Move the cursor position after the inserted tab character
        event.target.selectionStart = event.target.selectionEnd = selectionStart + 1;
      });
    }
  }

  addImage(pet_name, url) {
    let newItemMarkdown = '![' +pet_name + '](' + url+')\n';
    this.setState(prevState => ({
      markdown: prevState.markdown + newItemMarkdown
    }));
    this.handleClose();
  }

  openImageChooser() {
    this.setState({showImageChooser : true});
  }

  handleErrorMessageClose() {
    this.setState({showErrorMessage : false});
  }

  handleSaveMessageClose() {
    this.setState({showSaveMessage : false});
    window.location.href="/articles/" + this.state.id;
  }

  handleDeleteClose() {
    this.setState({showDeleteModal : false});
  }

  async deleteArticle() {
    let requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
    };
    const response = await fetch(API + '/article?id=' + this.state.id , requestOptions);
    const data = await response.json();
    if(response.ok){
      this.setState({showDeleteSuccess : true});
    }
    else{
      this.setState({errorMessage : data.reason});
      this.setState({showErrorMessage : true});
    }
    this.setState({showDeleteModal : false});
  }

  handleDeleteSuccessClose() {
    this.setState({showDeleteSuccess : false});
    window.location.href="/articles";
  }

  async publishArticle() {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
      body: JSON.stringify({id : this.state.id})
    };
    const response = await fetch(API + '/publish-article' , requestOptions);
    const data = await response.json();
    if(response.ok){
      this.setState({showPublishSuccess : true});
    }
    else{
      this.setState({errorMessage : data.reason});
      this.setState({showErrorMessage : true});
    }
  }

  handlePublishClose(){
    this.setState({showPublishSuccess : false});
  }
 
  render() {
    const { markdown } = this.state;
    const {titleMarkdown} = this.state;
    const previewHtml = marked(titleMarkdown + "\n" + markdown, { sanitize: true });

    return (
        <div>
          {this.state.loggedOut?
             <div className="center">
               <h1>You've been logged out</h1>
               <Button href="/">To Login</Button>
              </div>
              :
              <div>
              <NavigationBar />
              <div className="editor-container">
                <h1 className="center">Article Editor</h1>
                <hr></hr>
                <Button variant='dark' href="/articles">Back</Button>
                <div className="markdown-editor">
                    <div className="editor">
                        <h2 className="center">Markdown Editor</h2>
                        <hr></hr>
                        <div className="grid-2">
                            <h4>Article Title:</h4>
                            <input placeholder='article title' value={this.state.title} onChange={e => this.handleTitleChange(e.target.value)}></input>
                        </div>
                        <br></br>
                        <div className="article-editor-toolbar">
                            <div className="article-toolbar-container">
                              <p><b>Tool Bar</b></p>
                              <hr></hr>
                              <Button variant="light" onClick={() => this.handleAddItem('heading 2')}><b>+ H2</b></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('heading 3')}><b>+ H3</b></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('heading 4')}><b>+ H4</b></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('bold')}><b>+</b> <FaBold /></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('italicised')}><b>+</b> <FaItalic /></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('unordered list')}><b>+ <CiCircleList /></b></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('ordered list')}><b>+ <GoListOrdered /></b></Button>{' '}
                              <Button variant="light" onClick={this.openImageChooser}><b>+ <CiImageOn /></b></Button>{' '}
                              <Button variant="light" onClick={() => this.handleAddItem('link')}><b>+</b> <CiLink /></Button>
                            </div>
                        </div>
                        <textarea
                        value={markdown}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyPress}
                        placeholder="Enter your markdown here..."
                        className="markdown-text-area"
                        />
                        <div className="float-right">
                          {this.state.id === "-1"? 
                          <Button size="lg" variant="danger" disabled="true">Delete</Button>
                            :
                            <Button size="lg" variant="danger" onClick={() => this.setState({showDeleteModal : true})}>Delete</Button>}
                          <Button size="lg" onClick={this.saveArticle}>Save</Button>
                          {this.state.id === "-1"? 
                          <Button size="lg" variant="dark" disabled="true">Publish</Button>
                            :
                            <Button size="lg" variant="dark" onClick={this.publishArticle}>Publish</Button>}
                        </div>
                    </div>
                    <div className="preview">
                        <h2 className="center">Preview</h2>
                        <hr></hr>
                        <Container>
                          <div dangerouslySetInnerHTML={{ __html: previewHtml }} className="article-preview"/>
                        </Container>
                    </div>
              </div>
              <Modal show={this.state.showImageChooser} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Image Chooser</Modal.Title>
                </Modal.Header>
                  <Modal.Body>
                    <Form.Select aria-label="Default select example" onChange={e => this.getPetPictures(e.target.value)}>
                      <option>Select The Type Of Animal You're Looking For</option>
                      {this.state.petTypes.map((PetType, index) =>
                          <option key={index} value={PetType.type}>{PetType.type}</option> 
                      )}
                    </Form.Select>
                    <div className="image-selector-container">
                      {this.state.selectedPetImages.map((pet, index) =>
                        <>{pet.picture_url? 
                          <Card key={index} onClick={() => this.addImage(pet.name, pet.picture_url)} className="picture-card-click">
                            <Card.Header>{pet.name}</Card.Header>
                            <Card.Body><Image src={pet.picture_url} alt={pet.name}/></Card.Body>
                          </Card>
                          :
                          <div></div>
                          }
                        </>
                      )}
                    </div>
                  </Modal.Body>
              </Modal>

              <Modal show={this.state.showErrorMessage} onHide={this.handleErrorMessageClose}>
                <Modal.Header closeButton>
                  <Modal.Title> <span className="error-text">Error</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.errorMessage}
                </Modal.Body>
              </Modal>

              <Modal show={this.state.showSaveMessage} onHide={this.handleSaveMessageClose}>
                <Modal.Header closeButton>
                  <Modal.Title> <span className="success-text">Saved</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  The Article has been saved successfuly!
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={this.handleSaveMessageClose}>Close</Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this article?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={this.handleDeleteClose}>Close</Button>
                  <Button variant="danger" onClick={this.deleteArticle}>Delete</Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showDeleteSuccess} onHide={this.handleDeleteSuccessClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Article Deleted</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  The article was deleted successfully.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={this.handleDeleteSuccessClose}>Close</Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showPublishSuccess} onHide={this.handlePublishClose}>
                <Modal.Header closeButton>
                  <Modal.Title className="success-text">Article Published</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  The article was published successfully!
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={this.handlePublishClose}>Close</Button>
                </Modal.Footer>
              </Modal>
              </div>
            </div>
          }
        </div>
    );
  }
}

export default ArticlesEditor;
