import React, { Component } from 'react';
import {Spinner, Card, Container, Button } from 'react-bootstrap';
import { API } from '../variables/variables'; 
import Cookies from 'js-cookie';
import NavigationBar from '../../NavigationBar';

class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            loading: true,
            loggedOut : false
        }
        this.goToPage = this.goToPage.bind(this);
    }

    async componentDidMount() {
        let response = await fetch(API + '/articles', {
            method: 'GET',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
              }
        });
        
        if(response.ok){
            let data = await response.json();
            this.setState({articles : data.data});
        }
        else{
            this.setState({loggedOut : true})
        }
        this.setState({loading : false});
    }


    goToPage(id) {
        window.location.href = "/articles/"+id;
    }


    render() {
        return (
            <div>
                {this.state.loggedOut?
                    <div className="center">
                        <h1>You've been logged out.</h1>
                        <Button href='/'>TO Login</Button>
                    </div>
                    :
                    <div>
                        <NavigationBar />
                        <Container>
                            <h1>Articles</h1>
                            <br></br>
                            {this.state.loading? 
                                <div className="center"><Spinner /></div>
                                :
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                    {this.state.articles.map((article, index) =>
                                        <Card className="pet-card" key={index} style={{ width: '300px' }} onClick={() => this.goToPage(article.article_id)}>
                                            <Card.Header style={{ backgroundColor: '#fea910' }}>
                                                <div className="center">
                                                    <b>{article.article_name}</b>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <ul>
                                                    <li><b>Published: </b> {article.published? <span>True</span> : <span>False</span>}</li>
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                            }
                        </Container>
                    </div>
                }
                
            </div>
        )
    }
}

export default Articles;