import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomeScreen from './Home';
import PageNotFound from './PageNotFound';
import AnimalTypeGallery from './components/AnimalTypeGallery/AnimalTypeGallery';
import Assignments from './components/Assignments/Assignments';
import CreateAssignment from './components/Assignments/CreateAssignment';
import Articles from './components/Articles/Articles';
import ArticlesEditorContainer from './components/Articles/ArticlesEditorContainer';
import EditPetContainer from './components/EditPet/EditPetContainer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/edit/:id" element={<EditPetContainer />}/>
        <Route path="/animal/:type" element={<AnimalTypeGallery />} />
        <Route path="/assignments" element={<Assignments />} />
        <Route path="/create-assignment" element={<CreateAssignment />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:id" element={<ArticlesEditorContainer />} />
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
