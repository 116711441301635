import React, { Component } from 'react';
import { Form} from 'react-bootstrap';
import RequirementsComponent from './RequirementsComponent/RequirementsComponent';
import LinkAndRequirementsComponent from './RequirementsComponent/LinkAndRequirementsComponent';

class RequirementsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            habitat:this.props.habitat,
            diet:this.props.diet,
            hygiene:this.props.hygiene,
            exercise:this.props.exercise,
            vet:this.props.vet,
            training:this.props.training,
        }
    }

    render() {
        return (
            <div className="inside-component">
                
                <div>
                    <h5 className="form-section-name-inner"><b>Habitat Requirements *</b></h5>
                    <div className="form-section">
                        <RequirementsComponent 
                            title={'Habitat'} 
                            requirements={this.state.habitat}
                            updateParent={this.props.updateHabitat}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Diet Requirements *</b></h5>
                    <div className="form-section">
                        <RequirementsComponent 
                            title={'Diet'} 
                            requirements={this.state.diet}
                            updateParent={this.props.updateDiet}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Hygiene Requirements *</b></h5>
                    <div className="form-section">
                        <RequirementsComponent 
                            title={'Hygiene'} 
                            requirements={this.state.hygiene}
                            updateParent={this.props.updateHygiene}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Exercise Requirements *</b></h5>
                    <div className="form-section">
                        <RequirementsComponent 
                            title={'Exercise'} 
                            requirements={this.state.exercise}
                            updateParent={this.props.updateExercise}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Health / Vet Requirements *</b></h5>
                    <div className="form-section">
                        <LinkAndRequirementsComponent 
                            title={'Health / Vet'}
                            data={this.props.vet}
                            updateParent={this.props.updateVet}/>
                    </div>
                    <br></br>
                </div>
                <div>
                    <h5 className="form-section-name-inner"><b>Training Requirements *</b></h5>
                    <div className="form-section">
                        <RequirementsComponent 
                            title={'Training'} 
                            requirements={this.state.training}
                            updateParent={this.props.updateTraining}/>
                    </div>
                    <br></br>
                </div>
            </div>
        )
    }
}

export default RequirementsSection;