import React, { Component } from 'react';
import { Button, Form} from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";

class PackageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            urls: this.props.urls
        }
        this.addURL = this.addURL.bind(this);
        this.deleteURL = this.deleteURL.bind(this);
        this.changeURL = this.changeURL.bind(this);
    }

    addURL() {
        let urls = this.state.urls;
        urls.push({'item_name' : '', 'url' : ''});
        this.setState({urls: urls}, () => {
            this.props.updateParent(urls);
        });
    }

    deleteURL(index) {
        let new_urls = this.state.urls;
        new_urls.splice(index, 1);
        this.setState({urls : new_urls}, () => {
            this.props.updateParent(new_urls);
        });
    }

    changeURL(index, section, newValue) {
        let urls = this.state.urls;
        urls[index][section] = newValue;
        this.setState({urls : urls}, () => {
            this.props.updateParent(urls);
        });
    }

    render() {
        return ( 
            <div>
                <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                    {/*A requirement*/}
                    {this.state.urls.map((url, index) => 
                        <Form.Group className="mb-3" key={index}>
                            <br></br>
                            <Form.Label><b>Item {index+1}</b></Form.Label>
                            <div className="row">
                                <div className="col boxed">
                                    <Form.Label><b>{this.state.title} Item Type {index+1} *</b></Form.Label>
                                    <Form.Control 
                                        type="text"
                                        spellCheck={true}
                                        placeholder={this.state.title + " Item Type"}
                                        value={url.item_name}
                                        onChange={e => this.changeURL(index, 'item_name', e.target.value)}/>
                                    <Form.Text className="text-muted">Ex.) Hair Brush</Form.Text>
                                    <br></br>
                                    <Form.Label><b>{this.state.title} URL {index+1}*</b></Form.Label>
                                    <Form.Control type="text" 
                                    spellcheck={false} 
                                    placeholder={this.state.title + " Affiliate URL"}
                                    value={url.url} 
                                    onChange={e => this.changeURL(index,'url', e.target.value)}/>
                                    <br></br>
                                </div>
                                <div className="col-auto">
                                    <Button variant="danger" onClick={() => this.deleteURL(index)}><FaTrashAlt /></Button>
                                </div>
                            </div>
                        </Form.Group>
                    )}
                </Form>
                <Button className="add-button" variant="dark" size="sm" onClick={this.addURL}>+ URL</Button>
            </div>
        )
    }
}

export default PackageComponent;