import React, { Component } from 'react';
import { Button, Form} from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";

class PersonalityAdjectives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            requirements: this.props.requirements
        }
        this.addRequirement = this.addRequirement.bind(this);
        this.deleteRequiment = this.deleteRequiment.bind(this);
        this.changeRequirement = this.changeRequirement.bind(this);
    }

    addRequirement() {
        let requirements = this.state.requirements;
        requirements.push('');
        this.setState({requirements : requirements}, () => {
            this.props.updateParent(requirements);
        });
    }

    deleteRequiment(index) {
        let new_requirements = this.state.requirements;
        new_requirements.splice(index, 1);
        this.setState({requirements : new_requirements}, () => {
            this.props.updateParent(new_requirements);
        });
    }

    changeRequirement(index, newValue) {
        let requirements = this.state.requirements;
        requirements[index] = newValue;
        this.setState({requirements : requirements}, () => {
            this.props.updateParent(requirements);
        });
    }

    render() {
        return ( 
            <div>
                <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                    {/*A requirement*/}
                    {this.state.requirements.map((req, index) => 
                        <Form.Group className="mb-3" key={index}>
                            <Form.Label><b>{this.state.title} Adjective {index+1}*</b></Form.Label>
                            <br></br>
                            <div className="row">
                                <div className="col">
                                    <textarea rows="2" 
                                    className="requirement-text-area form-control" 
                                    placeholder={"Adjective"}
                                    onChange={e => this.changeRequirement(index, e.target.value)}
                                    value={req}
                                    ></textarea>
                                </div>
                                <div className="col-auto">
                                    <Button variant="danger" onClick={() => this.deleteRequiment(index)}><FaTrashAlt /></Button>
                                </div>
                            </div>
                        </Form.Group>
                    )}
                </Form>
                <Button className="add-button" variant="dark" size="sm" onClick={this.addRequirement}>+ Adjective</Button>
            </div>
        )
    }
}

export default PersonalityAdjectives;