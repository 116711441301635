import React from "react";
import { useParams } from "react-router-dom";
import AnimalTypePets from "./AnimalTypePets";


const AnimalTypeGallery = () => {
    const { type } = useParams();
    return <div><AnimalTypePets pets_type={type}/></div>
};

export default AnimalTypeGallery;