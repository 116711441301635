import React, { Component } from 'react';
import { Form} from 'react-bootstrap';
import { IoScaleSharp } from "react-icons/io5";
import { GiBodyHeight } from "react-icons/gi";
import { FaHeartbeat } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa6";
import { MdChildFriendly } from "react-icons/md";
import { GiCommercialAirplane } from "react-icons/gi";
import { FaBoxTissue } from "react-icons/fa";
import { FaPeace } from "react-icons/fa";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import PersonalityAdjectives from './PersonalityAdjectivesComponent/PersonalityAdjectives';

class QuickProfileSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height_range: this.props.height_range,
            weight_range: this.props.weight_range,
            lifespan_range: this.props.lifespan_range,
            child_friendly: this.props.child_friendly,
            travel_friendly : this.props.travel_friendly,
            hypoallergenic : this.props.hypoallergenic,
            coexist : this.props.coexist,
            handleable : this.props.handleable,
            sheds : this.props.sheds,
            activity_level: this.props.activity_level,
            affection_level: this.props.affection_level,
            living_environment: this.props.living_environment,
        }
        this.handleHeightRangeChange = this.handleHeightRangeChange.bind(this);
        this.handleWeightRangeChange = this.handleWeightRangeChange.bind(this);
        this.handleLifespanRangeChange = this.handleLifespanRangeChange.bind(this);
    }

    handleHeightRangeChange(value, index) {
        let height_range = this.state.height_range;
        height_range[index] = value;
        this.setState({height_range : height_range}, () => {
            this.props.updateHeightRange(height_range);
        });
    }

    handleWeightRangeChange(value, index) {
        let weight_range = this.state.weight_range;
        weight_range[index] = value;
        this.setState({weight_range : weight_range}, () => {
            this.props.updateWeightRange(weight_range);
        });
    }

    handleLifespanRangeChange(value, index) {
        let lifespan_range = this.state.lifespan_range;
        lifespan_range[index] = value;
        this.setState({lifespan_range: lifespan_range}, () => {
            this.props.updateLifespanRange(lifespan_range);
        });
    }

    handleActivityLevelChange(value) {
        this.setState({activity_level : value}, () => {
            this.props.updateActivityLevel(value);
        });
    }

    handleAffectionLevelChange(value, index) {
        this.setState({affection_level : value}, () => {
            this.props.updateAffectionLevel(value);
        });
    }

    handleLivingEnvrionmentChange(value) {
        let living_environment = this.state.living_environment;
        if (living_environment.includes(value)) {
            living_environment = living_environment.filter(i => i !== value);
          } else {
            living_environment.push(value);
          }
        this.setState({living_environment : living_environment}, () => {
            this.props.updateLivingEnvironment(living_environment);
        });
    }

    handleChildFreindlyChange(value) {
        this.setState({child_friendly : value}, () => {
            this.props.updateChildFriendly(value);
        });
    }

    handleTravelFreindlyChange(value) {
        this.setState({travel_friendly : value}, () => {
            this.props.updateTravelFrendly(value);
        });
    }

    handleHypoallergenicChange(value) {
        this.setState({hypoallergenic : value}, () => {
            this.props.updateHypoallergenic(value);
        })
    }

    handleCoexistChange(value) {
        this.setState({coexist : value}, () => {
            this.props.updateCoexist(value);
        });
    }

    handleHandleableChange(value) {
        this.setState({handleable : value}, () => {
            this.props.updateHandleable(value);
        });
    }

    handleShedsChange(value) {
        this.setState({sheds : value}, () => {
            this.props.updateSheds(value);
        });
    }

    render() {
        return ( 
            <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                {/*Pets height in inches*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Height in Inches * <GiBodyHeight /></b></Form.Label>
                    <div style={{ display: 'flex' }}>
                        <Form.Control
                            type="text"
                            placeholder="Start Height (inches)"
                            value={this.state.height_range[0]}
                            onChange={e => this.handleHeightRangeChange(e.target.value, 0)}
                            style={{ marginRight: '10px' }}
                        />
                        <span style={{ marginRight: '10px' }}>to</span>
                        <Form.Control
                            type="text"
                            placeholder="End Height (inches)"
                            value={this.state.height_range[1]}
                            onChange={e => this.handleHeightRangeChange(e.target.value, 1)}
                        />
                    </div>
                </Form.Group>

                {/*Pets weight in pounds*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Weight in Pounds * <IoScaleSharp /></b></Form.Label>
                    <div style={{ display: 'flex' }}>
                        <Form.Control
                            type="text"
                            placeholder="Start Weight (lbs)"
                            value={this.state.weight_range[0]}
                            onChange={e => this.handleWeightRangeChange(e.target.value, 0)}
                            style={{ marginRight: '10px' }}
                        />
                        <span style={{ marginRight: '10px' }}>to</span>
                        <Form.Control
                            type="text"
                            placeholder="End Weight (lbs)"
                            value={this.state.weight_range[1]}
                            onChange={e => this.handleWeightRangeChange(e.target.value, 1)}
                        />
                    </div>
                </Form.Group>

                {/*Pets lifespan in years*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Lifespan in Years * <FaHeartbeat /></b></Form.Label>
                    <div style={{ display: 'flex' }}>
                        <Form.Control
                            type="text"
                            placeholder="Start Lifespan (years)"
                            value={this.state.lifespan_range[0]}
                            onChange={e => this.handleLifespanRangeChange(e.target.value, 0)}
                            style={{ marginRight: '10px' }}
                        />
                        <span style={{ marginRight: '10px' }}>to</span>
                        <Form.Control
                            type="text"
                            placeholder="End Lifespan (years)"
                            value={this.state.lifespan_range[1]}
                            onChange={e => this.handleLifespanRangeChange(e.target.value, 1)}
                        />
                    </div>
                </Form.Group>

                {/*Pet is Child Friendly or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Child Friendly * <MdChildFriendly /></b></Form.Label>
                    <Form.Select onChange={e => this.handleChildFreindlyChange(e.target.value)} value={this.state.child_friendly}>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Form.Select>
                </Form.Group>

                {/*Pet is Travel Friendly or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Travel Friendly * <GiCommercialAirplane/></b></Form.Label>
                    <Form.Select onChange={e => this.handleTravelFreindlyChange(e.target.value)} value={this.state.travel_friendly}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Form.Select>
                </Form.Group>

                {/*Pet is Hypoallergenic or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Hypoallergenic * <FaBoxTissue/></b></Form.Label>
                    <Form.Select onChange={e => this.handleHypoallergenicChange(e.target.value)} value={this.state.hypoallergenic}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Form.Select>
                </Form.Group>

                {/*Pet can coexist or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Coexist * <FaPeace/></b></Form.Label>
                    <Form.Select onChange={e => this.handleCoexistChange(e.target.value)} value={this.state.coexist}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Form.Select>
                    <Form.Text className="muted">If this animal can coexist with others in it spieces.</Form.Text>
                </Form.Group>

                {/*Pet can be held or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Handleable * <FaHandsHoldingCircle /></b></Form.Label>
                    <Form.Select onChange={e => this.handleHandleableChange(e.target.value)} value={this.state.handleable}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Form.Select>
                    <Form.Text className="muted">If this animal can be held.</Form.Text>
                </Form.Group>

                {/*Pet sheds or not*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Sheds *</b></Form.Label>
                    <Form.Select onChange={e => this.handleShedsChange(e.target.value)} value={this.state.sheds}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Form.Select>
                    <Form.Text className="muted">If this animal sheds (lizzards shed skin).</Form.Text>
                </Form.Group>

                {/* Updating the personality of the animal */}
                <Form.Label><b>Personality Adjectives *</b></Form.Label>
                <div className="form-section">
                    <PersonalityAdjectives title="Personality" requirements={this.props.personality} updateParent={this.props.updatePersonality}/>
                </div>
                <Form.Text className="muted">One word adjectives to describe the animals personality.</Form.Text>
                <br></br>
                <br></br>
                {/*Pets active scale*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Activity Level * <FaRunning /></b></Form.Label>
                    <Form.Select aria-label="Default select example" value={this.state.activity_level} onChange={e => this.handleActivityLevelChange(e.target.value)}>
                        <option vlaue="Relaxed">Relaxed</option>
                        <option value="Moderately Active">Moderately Active</option>
                        <option value="Active">Active</option>
                    </Form.Select>
                </Form.Group>

                {/*Pets affection scale*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Affection Level * <FaHandHoldingHeart /></b></Form.Label>
                    <Form.Select aria-label="Default select example" value={this.state.affection_level} onChange={e => this.handleAffectionLevelChange(e.target.value)}>
                        <option vlaue="Not Affectionate">Not Affectionate</option>
                        <option value="Moderately Affectionate">Moderately Affectionate</option>
                        <option value="Very Affectionate">Very Affectionate</option>
                    </Form.Select>
                </Form.Group>

                {/*Type of living the pet is suitable for*/}
                <Form.Group className="mb-3">
                    <Form.Label><b>Best Suited Living Environment *</b></Form.Label>
                    <div className="living-grid">
                        {/*Types of homes list.
                            1. Indoor Only Home
                            2. Indoor & Enclosed Outdoor Home
                            3. Indoor & Open Outdoor Home
                            4. Farm
                        */}
                        <div onClick={() => this.handleLivingEnvrionmentChange("Tank")} 
                            className={this.state.living_environment.includes("Tank")? "center selected-living" : "center"}>
                            
                            <p>Tank</p>
                            <Form.Text className="text-muted">
                                ex.) Fish, turtles, lizzards
                            </Form.Text>
                        </div>
                        <div onClick={() => this.handleLivingEnvrionmentChange("Cage")}  
                            className={this.state.living_environment.includes("Cage")? "center selected-living" : "center"}>
                            
                            <p>Cage</p>
                            <Form.Text className="text-muted">
                                ex.) Hamsters
                            </Form.Text>
                        </div>
                        <div onClick={() => this.handleLivingEnvrionmentChange("Indoors")}  
                            className={this.state.living_environment.includes("Indoors")? "center selected-living" : "center"}>
                            
                            <p>Indoors</p>
                            <Form.Text className="text-muted">
                                ex.) Indoor cats and dogs
                            </Form.Text>
                            
                        </div>
                        <div onClick={() => this.handleLivingEnvrionmentChange("Outdoors")}  
                            className={this.state.living_environment.includes("Outdoors")? "center selected-living" : "center"}>
                            
                            <p>Outdoors</p>
                            <Form.Text className="text-muted">
                                ex.) A tortoise
                            </Form.Text>
                        </div>
                        <div onClick={() => this.handleLivingEnvrionmentChange("Indoors & Outdoors")}  
                            className={this.state.living_environment.includes("Indoors & Outdoors")? "center selected-living" : "center"}>
                           
                            <p>Indoors & Outdoors</p>
                            <Form.Text className="text-muted">
                                ex.) Dogs
                            </Form.Text>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        )
    }
}

export default QuickProfileSection;