import React, { Component } from 'react';
import { Button, Form} from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";

class LinkAndRequirementsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            data: this.props.data
        }
        this.addIllness = this.addIllness.bind(this);
        this.deleteIllness = this.deleteIllness.bind(this);
        this.changeIllness = this.changeIllness.bind(this);
        this.addRequirement = this.addRequirement.bind(this);
        this.deleteRequiment = this.deleteRequiment.bind(this);
        this.changeRequirement = this.changeRequirement.bind(this);
    }

    addIllness() {
        let data = this.state.data;
        data.illnesses.push({'name' : '', 'definition' : '', 'link' : ''});
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    deleteIllness(index) {
        let data = this.state.data;
        let new_illnesses = data.illnesses;
        new_illnesses.splice(index, 1);
        data.illnesses = new_illnesses;
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    changeIllness(index, type, newValue){
        let data = this.state.data;
        let new_illnesses = data.illnesses;
        new_illnesses[index][type] = newValue;
        data.illnesses = new_illnesses;
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    addRequirement() {
        let data = this.state.data;
        let requirements = data.requirements;
        requirements.push('');
        data.requirements = requirements;
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    deleteRequiment(index) {
        let data = this.state.data;
        let new_requirements = data.requirements;
        new_requirements.splice(index, 1);
        data.requirements = new_requirements;
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    changeRequirement(index, newValue) {
        let data = this.state.data;
        let requirements = data.requirements;
        requirements[index] = newValue;
        data.requirements = requirements;
        this.setState({data : data}, () => {
            this.props.updateParent(data);
        });
    }

    render() {
        return ( 
            <div>
                <Form onSubmit={(event) => {event.preventDefault();}} className="form-section-form">
                    {/*A requirement*/}
                    {this.state.data.requirements.map((req, index) => 
                        <Form.Group className="mb-3" key={index}>
                            <Form.Label><b>{this.state.title} Requirement {index+1} *</b></Form.Label>
                            <br></br>
                            <div className="row">
                                <div className="col">
                                    <textarea rows="2" 
                                    className="requirement-text-area form-control" 
                                    placeholder={this.state.title + " Requirement"}
                                    value={req}
                                    onChange={e => this.changeRequirement(index, e.target.value)}
                                    ></textarea>
                                </div>
                                <div className="col-auto" onClick={() => this.deleteRequiment(index)}>
                                    <Button variant="danger"><FaTrashAlt /></Button>
                                </div>
                            </div>
                        </Form.Group>
                    )}
                    
                    
                    {this.state.data.illnesses.map((illness, index) =>
                        <Form.Group className="mb-3 bordered" key={index}>
                        <Form.Label><b>{this.state.title} Illness {index+1} *</b></Form.Label>
                        <br></br>
                        <div className="row">
                            <div className="col">
                                <Form.Label>Illness Name *</Form.Label>
                                <Form.Control type="text" placeholder="Enter illness name" value={illness.name}
                                    onChange={e => this.changeIllness(index, 'name', e.target.value)}    
                                />
                                <Form.Text className="text-muted">
                                    This is the name of the illness.
                                </Form.Text>
                                <br></br>
                                <br></br>
                                <Form.Label>Illness Definition *</Form.Label>
                                <textarea rows="2" 
                                    className="requirement-text-area form-control" 
                                    placeholder={this.state.title + " Illness Definition"}
                                    value={illness.definition}
                                    onChange={e => this.changeIllness(index, 'definition', e.target.value)}
                                ></textarea>
                                 <Form.Text className="text-muted">
                                    This is the definition of the illness.
                                </Form.Text>
                                 <br></br>
                                 <br></br>
                                 <Form.Label>Illness Link To Outside Source</Form.Label>
                                 <textarea rows="2" 
                                    className="requirement-text-area form-control" 
                                    placeholder={this.state.title + " Illness Link To Outside Source"}
                                    value={illness.link}
                                    onChange={e => this.changeIllness(index, 'link', e.target.value)}
                                 ></textarea>
                                 <Form.Text className="text-muted">
                                    This is the link to an outside source of the illness.
                                </Form.Text>
                            </div>
                            <div className="col-auto">
                                <Button variant="danger" onClick={() => this.deleteIllness(index)}><FaTrashAlt /></Button>
                            </div>
                        </div>
                    </Form.Group>)}
                </Form>
                <Button className="add-button" variant="dark" size="sm" onClick={this.addRequirement}>+ Requirement</Button>
                <Button className="add-button" variant="dark" size="sm" onClick={this.addIllness}>+ Illness</Button>
            </div>
        )
    }
}

export default LinkAndRequirementsComponent;