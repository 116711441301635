import React, { Component } from 'react';
import { Container, Button} from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import ChatGPTQueries from './EditPetComponents/ChatGPTQueries';
import EditPetForm from './EditPetComponents/EditPetForm';

class EditPet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pet_id : this.props.pet_id,
            formSelected: true
        }
        this.switch = this.switch.bind(this);
    }

    switch() {
        this.setState({formSelected : !this.state.formSelected});
    }

    render() {
        return (
            <div>
                <NavigationBar />
                <Container>
                    <h1>Pet Quick Start Edit Pet Form</h1>
                    <br></br>
                    <Button variant="dark" href="/">Back</Button>
                    <div className="title-buttons-container">
                        <div className={this.state.formSelected?"center title-button-active" : "center title-button"} onClick={this.switch}>
                            <h2>Form</h2>
                        </div>
                        <div className={this.state.formSelected?"center title-button" : "center title-button-active"} onClick={this.switch}>
                            <h2>Queries</h2>
                        </div>
                    </div>
                    <br></br>
                    
                    <div className={this.state.formSelected ? "" : "hide"}>
                        <EditPetForm pet_id={this.state.pet_id}/>
                    </div>
                    <div className={this.state.formSelected ? "hide" : ""}>
                        <ChatGPTQueries />
                    </div>
                </Container>
            </div>
        )
    }
}

export default EditPet;