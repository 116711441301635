import React from "react";
import { useParams } from "react-router-dom";
import ArticlesEditor from "./ArticlesEditor";


const ArticlesEditorContainer = () => {
    const { id } = useParams();
    return <div><ArticlesEditor id={id}/></div>
};

export default ArticlesEditorContainer;