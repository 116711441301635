import React, { Component } from 'react';
import {Spinner, Container, Button, Table, Modal, Form, Card } from 'react-bootstrap';
import { API } from '../variables/variables'; 
import Cookies from 'js-cookie';
import NavigationBar from '../../NavigationBar';

class Assignments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignments: [],
            filteredAssignments: [],
            loading: true,
            loggedOut: false,
            showSuccessModal: false,
            admin_filter: "*",
            status_filter: "*",
            admin_options: ["*", "shawn", "drew", "ferg", "ares"],
            status_options: ["*", "Finished", "Not Finished"]
        }
        this.handleClose = this.handleClose.bind(this);
        this.filter = this.filter.bind(this);
        this.setAdminFilter = this.setAdminFilter.bind(this);
        this.setStatusFilter = this.setStatusFilter.bind(this);
    }

    async componentDidMount() {
        let response = await fetch(API + '/assignments', {
            method: 'GET',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
              }
        });
        
        if(response.ok){
            let data = await response.json();
            this.setState({assignments : data.data}, () => {
                this.filter();
            });
        }
        else {
            this.setState({loggedOut : true});
        }
        this.setState({loading : false});
    }

    async deleteAssignment(id) {
        let response = await fetch(API + '/assignments?id=' + id, {
            method: 'DELETE',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
              }
        });
        let data = response.json();
        if(response.ok){
            this.setState({showSuccessModal : true});
        }
        else {
            alert(data.reason);
        }
        this.setState({loading : false});
    }

    filter() {
        let assignments = this.state.assignments;
        let filtered = [];
        let admin_filter = this.state.admin_filter;
        let status_filter = this.state.status_filter;
        // Filter by admin
        if (admin_filter === "*") {
            filtered = assignments.slice(); // Copy all items
        } else {
            filtered = assignments.filter(assignment => assignment.assigned_to === admin_filter);
        }

        // Filter by status
        if (status_filter !== "*") {
            filtered = filtered.filter(assignment => {
                if (status_filter === "Finished") {
                    return (assignment.published === true && assignment.exists_in_pets === true);
                } else {
                    return (assignment.published !== true || assignment.exists_in_pets !== true);
                }
            });
        }
        this.setState({filteredAssignments : filtered});
    }

    setAdminFilter(value) {
        this.setState({admin_filter : value}, () => {
            this.filter();
        });
    }

    setStatusFilter(value) {
        this.setState({status_filter : value}, ()=> {
            this.filter();
        });
    }

    handleClose() {
        this.setState({showSuccessModal : false});
        window.location.reload();
    }


    render() {
        return (
            <div>
                {this.state.loading? 
                    <div className="center"><Spinner /></div>
                    :
                    <div>
                        {this.state.loggedOut? 
                            <div className="center">
                                <h1 className="center">You've been logged out</h1>
                                <Button className="center" href="/">To Login</Button>
                            </div>
                            :
                            <div>
                                <NavigationBar />
                                <Container>
                                    <div className="filters">
                                        <br></br>
                                        <Card>
                                            <Card.Header><h3>Filters</h3>  </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Assigned To</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={e => this.setAdminFilter(e.target.value)} value={this.state.admin_filter}>
                                                            {this.state.admin_options.map((admin, index) => 
                                                                <option vlaue={admin} key={index}>{admin}</option>
                                                            )}
                                                        </Form.Select>
                                                        <Form.Text className="text-muted">
                                                        The person the assignment belongs to. "*" is all.
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={e => this.setStatusFilter(e.target.value)} value={this.state.status_filter}>
                                                            {this.state.status_options.map((status, index) => 
                                                                <option vlaue={status} key={index}>{status}</option>
                                                            )}
                                                        </Form.Select>
                                                        <Form.Text className="text-muted">
                                                        The status of the assignment. "*" is all.
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                               
                                    </div>
                                    <br></br>
                                    <div className="assignments">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                <th><b>Row Number</b></th>
                                                <th><b>Assigned To</b></th>
                                                <th><b>Pet Name</b></th>
                                                <th><b>Pet Type</b></th>
                                                <th><b>Exists In Pets Database</b></th>
                                                <th><b>Published</b></th>
                                                <th><b>Status</b></th>
                                                <th><b>Action</b></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.filteredAssignments.map((assignment, index) => 
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{assignment.assigned_to}</td>
                                                        <td>{assignment.pet_name}</td>
                                                        <td>{assignment.pet_type}</td>
                                                        <td>{assignment.exists_in_pets? <span className="success-text">Exists</span> : <span className="error-text">Does Not Exist</span>}</td>
                                                        <td>{assignment.published ? <span className="success-text">Published</span> : <span className="error-text">Not Published</span>}</td>
                                                        <td>{assignment.published && assignment.exists_in_pets? <span className="success-text">Finished</span> : <span className="error-text">Not Finished</span>}</td>
                                                        <td className="center"><Button variant="danger" size="sm" onClick={() => this.deleteAssignment(assignment.id)}>Delete</Button></td>
                                                    </tr>
                                                )}
                                                
                                            </tbody>
                                        </Table>
                                    </div>

                                </Container>
                            </div>
                        }
                    </div>
                }
                <Modal show={this.state.showSuccessModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><span className="error-text">Assignment Deleted!</span></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>The assignment has been deleted successfully</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Assignments;