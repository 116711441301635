import React, { Component } from 'react';
import { Card, Container, Spinner, Image } from 'react-bootstrap';
import { API } from '../variables/variables'; 
import Cookies from 'js-cookie';
import NavigationBar from '../../NavigationBar';

class AnimalTypePets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pets: [],
            pets_type: this.props.pets_type 
        }
        this.editPet = this.editPet.bind(this);
    }

    async componentDidMount() {
        let requestOptions = {};
        this.setState({showInvalidLogin : false});
        this.setState({showError : false});
        requestOptions = {
            method: 'GET',
            headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
        };
        const response = await fetch(API + '/pets?type=' + this.props.pets_type , requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({pets : data.data});
        }
        this.setState({loading : false});
    }

    editPet(id) {
        window.location.href = '/edit/' + id;
    }

    render() {
        return (
            <div>
                <NavigationBar />
                    <Container>
                    <div className="center">
                        <h1>{this.state.pets_type} Gallery</h1>
                    </div>
                    <br></br>
                    {this.state.loading? 
                        <div className="center">
                            <Spinner />
                        </div>
                        :
                        <div>
                            {this.state.pets.length === 0? 
                            <div className="center">
                                <h4>No {this.state.pets_type}s</h4>
                            </div>
                            :
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                {this.state.pets.map((pet, index) => 
                                    <Card className="pet-card" key={index} style={{ width: '300px' }} onClick={() => this.editPet(pet.id)}>
                                        <Card.Header className="center" style={{ backgroundColor: '#fea910' }}>
                                            <span>{pet.name}</span>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="center">
                                                {pet.picture_url !== null? <Image src={pet.picture_url} height="200px" width="200px"/> : <span>No picture</span>}
                                            </div>
                                            <br></br>
                                            <ul>
                                                <li><b>Published: </b> {pet.published? <span>Is Published</span> : <span>Not Published</span>}</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                )}
                            </div>
                            }
                        </div>
                        
                    }
                    <br></br>
                </Container>
                    
            </div>
        )
    }
}

export default AnimalTypePets;