import React, { Component } from 'react';
import { Container, Button, Image, Form, Spinner } from 'react-bootstrap';
import logo from './logo.png';
import { API } from './components/variables/variables'; 
import Cookies from 'js-cookie';
import PetGallery from './components/PetGallery/PetGallery';
import NavigationBar from './NavigationBar';

class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            username: "",
            password: "",
            showInvalidLogin: false,
            showError: false,
            pets: [],
            loading: true
        }
        this.login = this.login.bind(this);
    }

    async componentDidMount() {
        let requestOptions = {};
        this.setState({showInvalidLogin : false});
        this.setState({showError : false});
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('pqsToken')},
            body: JSON.stringify({})
        };
        const response = await fetch(API + '/authenticate', requestOptions);
        const data = await response.json();
        if(response.ok){
            if(data.Auth === true){
                this.setState({loggedIn : true});
            }
        }
        this.setState({loading : false});
    }

    async login() {
        let requestOptions = {};
        this.setState({showInvalidLogin : false});
        this.setState({showError : false});
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json'},
            body: JSON.stringify({'username' : this.state.username, 'password' : this.state.password})
        };
        const response = await fetch(API + '/authenticate', requestOptions);
        const data = await response.json();
        if(response.ok){
            if(data.auth_obj.Auth === true){
                Cookies.set('pqsToken', data.auth_obj.token, { expires: 7 });
                this.setState({loggedIn : true});
            }
            else{
                this.setState({showInvalidLogin : true});
            }
        }
        else{
            this.setState({showError : true});
        }
    }

    render() {
        return (
            <div>
                
                    {this.state.loading? 
                    <div className="center">
                        <Spinner />
                    </div>
                        :
                        <>
                            {this.state.loggedIn? 
                                <div>
                                <NavigationBar />
                                <Container>
                                <div>
                                    
                                    
                                    <h1>Pet Quick Start Manager Page</h1>
                                    <br></br>
                                    <br></br>
                                    <h3 className="center">Choose a Pet Type</h3>
                                    <br></br>
                                    <PetGallery />
                                    <br></br>
                                </div>
                                </Container>
                                </div>
                                :
                                <div>
                                    <div className="center">
                                        <h1 className="center"><Image src={logo} className="center" rounded height="75px" width="75px"></Image> PQS Manager</h1>
                                    </div>
                                    <br></br>
                                    <div>
                                        <Form className="login-form">
                                            <h2 className="center">Please Login</h2>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="username" placeholder="Enter username" value={this.state.username} onChange={e => this.setState({username : e.target.value})}/>
                                                <Form.Text className="text-muted">
                                                    Your given username.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={e => this.setState({password : e.target.value})}/>
                                            </Form.Group>
                                            <div className="d-grid gap-2">
                                                <Button variant="dark" onClick={this.login}>Login</Button>
                                            </div>
                                            
                                            {this.state.showInvalidLogin? 
                                                <>Invalid credentials</>
                                                :
                                                <></>
                                            }
                                            {this.state.showError?
                                                <>An error occured</>
                                                :
                                                <></>
                                            }
                                        </Form>
                                    </div>
                                    <br></br>
                                    <h4 className="center">Unauthorized Usage of This System Will Result In Legal Action</h4>
                                    
                                </div>
                                
                            
                            }
                        </>
                    }
                    
            </div>
        )
    }
}

export default HomeScreen;