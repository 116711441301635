import React, { Component } from 'react';
import { Card, Container, Button} from 'react-bootstrap';

class ChatGPTQueries extends Component {
    constructor(props) {
        super(props);
        this.difficultyRatingRef = React.createRef();
        this.query2Ref = React.createRef();
      }

    copyToClipboard = () => {
        const difficultyRatingText = this.difficultyRatingRef.current.innerText;
        navigator.clipboard.writeText(difficultyRatingText)
        .then(() => {
            alert('Query 1 copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
    };

    copyQuery2ToClipboard = () => {
      const query2Text = this.query2Ref.current.innerText;
        navigator.clipboard.writeText(query2Text)
        .then(() => {
            alert('Query 2 copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
    }

    render() {
        const difficultyRatingMarkdown = `
Using the following definitions, give me a difficulty rating for a [pet name here] and give me the reasons in a list. Tell me why you chose the difficulty rating for:

Overall Difficulty Rating: (Very Easy, Easy, Medium, Hard)

- Very Easy:
  - Minimal Time Commitment: Animals in this category require very little time and effort to care for. (1-2 hours per week)
  - Low Specialized Knowledge Required: Basic knowledge of animal care is sufficient.
  - Low Cost of Care: Minimal financial investment required for their care.
  - Low Maintenance: These animals typically require minimal grooming and cleaning.

- Easy:
  - Moderate Time Commitment: Regular but not extensive care is required. (3-5 hours per week)
  - Basic Knowledge Required: Some basic understanding of animal care is necessary.
  - Moderate Cost of Care: Some financial investment required but not excessive.
  - Moderate Maintenance: Regular grooming and cleaning are necessary, but not overly demanding.

- Medium:
  - Significant Time Commitment: These animals require regular and attentive care. (6-10 hours per week)
  - Specialized Knowledge Required: A good understanding of animal behavior and health is necessary.
  - Moderate to High Cost of Care: Significant financial investment may be required for their care.
  - Regular Maintenance: These animals require regular grooming, cleaning, and may have specific dietary or environmental needs.

- Hard:
  - High Time Commitment: These animals demand significant time and attention for their care. (10+ hours per week)
  - Advanced Knowledge Required: In-depth knowledge of animal behavior, health, and specialized care is necessary.
  - High Cost of Care: Substantial financial investment required for their care.
  - High Maintenance: These animals require frequent grooming, cleaning, and may have very specific dietary and environmental needs.
`;

      const query2 = `
      Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet add in some humor and give exact numbers or ranges when possible for all of the following topics for a [pet name here]. Add in anything I may have missed that is critical to the care of the pet and fit it into one of the sections. Make this in a friendly and conversational tone.
      Do not use colons.

      Habitat Requirements: Detail the necessary living environment for the pet, including housing, cage size (if applicable), temperature, humidity, and any specific habitat accessories or substrates needed.
      
      Diet and Nutrition: Outline the pet's dietary needs, including recommended types of food, portion sizes, feeding schedules, and any dietary restrictions or special considerations.
      
      Grooming and Hygiene: Explain grooming requirements such as bathing, brushing, nail trimming, and dental care. Include information on maintaining cleanliness in the pet's habitat.
      
      Exercise and Enrichment: Describe the pet's activity level and the importance of physical and mental stimulation. Offer suggestions for suitable toys, activities, and enrichment opportunities.
      
      Health and Veterinary Care: Discuss common health issues and preventive care measures, including vaccinations, parasite control, and regular check-ups. Provide guidance on recognizing signs of illness or injury and when to seek veterinary attention.
      
      Training and Socialization: Offer advice on training methods, socialization practices, and behavior management techniques specific to the species. Emphasize positive reinforcement and patience in training.
      
      Personality: Describe the specific traits this animal has that makes it unique and why people would either love it or hate having it around. Give certain examples of how this animal behaves.
      
      Compatibility and Considerations: Highlight factors to consider before bringing the pet into your home, such as compatibility with children, other pets, or individuals with allergies. Address any specific challenges or considerations associated with the species also, give the expected life span of the pet.  
      `;
        return (
            <div>
                <Container>
                <div>
                    <Card>
                      <Card.Header>
                        <h2 className="float-left">Query 1</h2>
                        <Button onClick={this.copyToClipboard} className="float-right">Copy Query 1</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.difficultyRatingRef}>
                          <code dangerouslySetInnerHTML={{ __html: difficultyRatingMarkdown }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Query 2</h2>
                       <Button onClick={this.copyQuery2ToClipboard} className="float-right">Copy Query 2</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query2Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query2 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    
                    
                    
                    <p></p>
                </div>
                </Container>
            </div>
        )
    }
}

export default ChatGPTQueries;