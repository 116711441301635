import React, { Component } from 'react';
import { Button, Spinner, spinner } from 'react-bootstrap';
import OverviewSection from './PetFormComponents/OverviewSection';
import DifficultySection from './PetFormComponents/DifficultySection';
import QuickProfileSection from './PetFormComponents/QuickProfileSection';
import RequirementsSection from './PetFormComponents/RequirementsSection';
import PackagesSection from './PetFormComponents/PackagesSection';
import { API } from '../../variables/variables';
import Cookies from 'js-cookie';

class EditPetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pet_id : this.props.pet_id,
            loading: true,
            /* Overview Section State */
            petName: "",
            profilePicture: null,
            currentProfilePicture: null,
            petType: null,

            /* Difficulty Section State */
            difficulty: "Very Easy",
            dif_time: "",
            dif_knowledge: "",
            dif_cost: "",
            dif_maintenance: "",

            /* Quick Profile Section State */
            height_range: [null, null],
            weight_range: [null, null],
            lifespan_range: [null, null],
            activity_level: "Relaxed",
            child_friendly: "true",
            travel_friendly: "false",
            hypoallergenic: "false",
            coexist: "false",
            handleable: "false",
            sheds: "false",
            affection_level: "Not Affectionate",
            living_environment: [],
            personality: [],

            /* Requirements Section */
            habitat : [],
            diet: [],
            hygiene: [],
            exercise: [],
            vet: {'requirements' : [], 'illnesses' : []},
            training: [],

            /* Packages */
            essentials: [],
            intermediate: [],
            advanced: [],

            /* Button Loading States */
            saveLoading: false,
            deleteLoading: false,
            publishLoading: false,
            takeDownLoading: false
        }

        /* Overview Binding */
        this.updatePetName = this.updatePetName.bind(this);
        this.updateProfilePicture = this.updateProfilePicture.bind(this);
        this.updatePetType = this.updatePetType.bind(this);

        /* Difficulty Binding */
        this.updateDifficulty = this.updateDifficulty.bind(this);
        this.updateDifTime = this.updateDifTime.bind(this);
        this.updateDifKnowledge = this.updateDifKnowledge.bind(this);
        this.updateDifCost = this.updateDifCost.bind(this);
        this.updateDifMaintenance = this.updateDifMaintenance.bind(this);

        /*Quick Profile Binding */
        this.updateHeightRange = this.updateHeightRange.bind(this);
        this.updateWeightRange = this.updateWeightRange.bind(this);
        this.updateLifespanRange = this.updateLifespanRange.bind(this);
        this.updateChildFriendly = this.updateChildFriendly.bind(this);
        this.updateTravelFriendly = this.updateTravelFriendly.bind(this);
        this.updateHypoallergenic = this.updateHypoallergenic.bind(this);
        this.updateCoexist = this.updateCoexist.bind(this);
        this.updateHandleable = this.updateHandleable.bind(this);
        this.updateSheds = this.updateSheds.bind(this);
        this.updateActivityLevel = this.updateActivityLevel.bind(this);
        this.updateAffectionLevel = this.updateAffectionLevel.bind(this);
        this.updateLivingEnvironment = this.updateLivingEnvironment.bind(this);
        this.updatePersonality = this.updatePersonality.bind(this);

        /*Requirements Binding */
        this.updateHabitat = this.updateHabitat.bind(this);
        this.updateDiet = this.updateDiet.bind(this);
        this.updateHygiene = this.updateHygiene.bind(this);
        this.updateExercise = this.updateExercise.bind(this);
        this.updateVet = this.updateVet.bind(this);
        this.updateTraining = this.updateTraining.bind(this);
        

        /*Packages Binding */
        this.updateEssentials = this.updateEssentials.bind(this);
        this.updateIntermediate = this.updateIntermediate.bind(this);
        this.updateAdvanced = this.updateAdvanced.bind(this);

        /*Button Function */
        this.savePet = this.savePet.bind(this);
        this.deletePet = this.deletePet.bind(this);
        this.publishPet = this.publishPet.bind(this);
        this.takeDown = this.takeDown.bind(this);

        this.removeWhiteSpaceFromVetData = this.removeWhiteSpaceFromVetData.bind(this);
    }

    removeWhiteSpaceFromVetData() {
        let vet = this.state.vet;
        let requirements = vet.requirements;
        for(let i = 0; i < requirements.length; i++){
            requirements[i] = requirements[i].trim();
        }
        vet.requirements = requirements;

        let illnesses = vet.illnesses;
        for(let i = 0; i < illnesses.length; i++){
            illnesses[i].name = illnesses[i].name.trim();
            illnesses[i].definition = illnesses[i].definition.trim();
            illnesses[i].link = illnesses[i].link.trim();
        }
        vet.illnesses = illnesses;
        return vet;
    }

    async savePet() {
        this.setState({saveLoading : true});
        const vet = this.removeWhiteSpaceFromVetData(); 
        const formData = new FormData();

        // Adding the overview section.
        formData.append('id', this.state.pet_id);
        formData.append('name', this.state.petName);
        formData.append('type', this.state.petType);
        formData.append('picture', this.state.profilePicture); // file is a File object

        // Adding the difficulty information.
        formData.append('difficulty', this.state.difficulty);
        formData.append('difficulty_factors', JSON.stringify({
            'dif_time': this.state.dif_time,
            'dif_knowledge': this.state.dif_knowledge,
            'dif_cost': this.state.dif_cost,
            'dif_maintenance': this.state.dif_maintenance,}));
        
        // Adding the quick profile section.
        formData.append('quick_profile', JSON.stringify({
            'height_range': this.state.height_range,
            'weight_range': this.state.weight_range,
            'lifespan_range': this.state.lifespan_range,
            'activity_level': this.state.activity_level,
            'child_friendly': this.state.child_friendly,
            'travel_friendly': this.state.travel_friendly,
            'affection_level': this.state.affection_level,
            'hypoallergenic' : this.state.hypoallergenic,
            'coexist' : this.state.coexist,
            'handleable' : this.state.handleable,
            'sheds' : this.state.sheds,
            'living_environment': this.state.living_environment,
            'personality': this.state.personality,
        }));

        // Adding the requirements section.
        formData.append('requirements', JSON.stringify({
            'habitat' : this.state.habitat,
            'diet': this.state.diet,
            'hygiene': this.state.hygiene,
            'exercise': this.state.exercise,
            'vet': vet,
            'training': this.state.training,
        }));

        formData.append('packages', JSON.stringify({
            'essentials': this.state.essentials,
            'intermediate': this.state.intermediate,
            'advanced': this.state.advanced
        }));
        let current_pet_response = await fetch(API + '/pet', {
            method: 'POST',
            headers: {
                'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
            },
            body: formData
        });
        if(current_pet_response.status === 200){
            alert("Pet Data Saved");
            const data = await current_pet_response.json();
            const redirect = data.new_id;
            if(redirect === true){
                window.location.href = data.url;
            }
        }
        else if(current_pet_response.status === 480 || current_pet_response.status === 500){
            const data = await current_pet_response.json();
            alert("Error: " + data.reason);
        }
        this.setState({saveLoading : false});
    }

    async deletePet() {
        this.setState({deleteLoading : true});
        if(this.state.pet_id === "-1"){
            alert("This pet is not saved. You cannot delete it.");
        }
        else{
            const result = window.confirm("Do you want to proceed?");
            if (result) {
                const current_pet_response = await fetch(API + '/pet?id=' + this.state.pet_id, {
                    method: 'DELETE',
                    headers: {
                        'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
                    }
                });
                if(current_pet_response.status === 200){
                    alert("Pet Deleted!");
                    let data = await current_pet_response.json();
                    window.location.href= data.url;
                }
                else if(current_pet_response.status === 480 || current_pet_response.status === 500){
                    let data = await current_pet_response.json();
                    alert("Error: " + data.reason);
                }
            } 
        }
        this.setState({deleteLoading : false});
    }

    async publishPet() {
        this.setState({publishLoading : true});
        if(this.state.pet_id === "-1"){
            alert("This pet is not saved. You cannot publish it.");
        }
        else{
            const result = window.confirm("Are you sure you want to publish this pet?");
            if (result) {
                const response = await fetch(API + '/publish-pet', {
                    method: 'POST',
                    headers: {
                        'Authorization' : 'Bearer ' + Cookies.get('pqsToken'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({id:this.state.pet_id})
                });
                if(response.status === 200){
                    alert("Pet published!");
                }
                else if(response.status === 480 || response.status === 500){
                    let data = await response.json();
                    alert("Error: " + data.reason);
                }
            }
        }
        this.setState({publishLoading : false});
    }

    async takeDown() {
        this.setState({takeDownLoading : true});
        if(this.state.pet_id === "-1"){
            alert("This pet is not saved. You cannot take it down.");
        }
        else{
            const result = window.confirm("Are you sure you want to take this pet down?");
            if (result) {
                const response = await fetch(API + '/take-down-pet', {
                    method: 'POST',
                    headers: {
                        'Authorization' : 'Bearer ' + Cookies.get('pqsToken'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({id:this.state.pet_id})
                });
                if(response.status === 200){
                    alert("Pet taken down!");
                }
                else if(response.status === 480 || response.status === 500){
                    let data = await response.json();
                    alert("Error: " + data.reason);
                }
            }
        }
        this.setState({takeDownLoading : false});
    }

    async componentDidMount() {
        if(this.props.pet_id === "-1"){
            console.log("new pet");
        }
        else{
            // Get the pet information to edit.
            let current_pet_response = await fetch(API + '/pet?id=' + this.props.pet_id, {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
                }
            });
            if(current_pet_response.ok){
                const data = await current_pet_response.json();
                const pet = data.data;
                if(pet === null || pet === undefined) {
                    this.setState({petIDNotValid : true});
                }
                else{
                    /* Setting Overview Section */
                    this.setState({petName : pet.name});
                    this.setState({petType : pet.type});
                    if(pet.picture_url !== null){
                        this.setState({currentProfilePicture : pet.picture_url});
                    }
                    
                    /* Setting Difficulty Section */
                    this.setState({difficulty : pet.difficulty_rating});
                    if(pet.difficulty_factors !== null && pet.difficulty_factors !== undefined){
                        this.setState({dif_cost : pet.difficulty_factors.dif_cost});
                        this.setState({dif_knowledge : pet.difficulty_factors.dif_knowledge});
                        this.setState({dif_time : pet.difficulty_factors.dif_time});
                        this.setState({dif_maintenance : pet.difficulty_factors.dif_maintenance});
                    }

                    /* Setting Quick Profile Section */
                    if(pet.quick_profile !== null && pet.quick_profile !== undefined){
                        this.setState({height_range : pet.quick_profile.height_range});
                        this.setState({weight_range : pet.quick_profile.weight_range});
                        this.setState({lifespan_range : pet.quick_profile.lifespan_range});
                        this.setState({child_friendly : pet.quick_profile.child_friendly});
                        this.setState({travel_friendly : pet.quick_profile.travel_friendly});
                        this.setState({activity_level : pet.quick_profile.activity_level});
                        this.setState({hypoallergenic : pet.quick_profile.hypoallergenic});
                        this.setState({coexist : pet.quick_profile.coexist});
                        this.setState({handleable : pet.quick_profile.handleable});
                        this.setState({sheds : pet.quick_profile.sheds});
                        this.setState({affection_level : pet.quick_profile.affection_level});
                        this.setState({personality : pet.quick_profile.personality});
                        this.setState({living_environment : pet.quick_profile.living_environment});
                    }

                    /* Setting Requirements Section */
                    this.setState({habitat : pet.requirements.habitat});
                    this.setState({diet : pet.requirements.diet});
                    this.setState({hygiene : pet.requirements.hygiene});
                    this.setState({exercise : pet.requirements.exercise});
                    this.setState({vet : pet.requirements.vet});
                    this.setState({training : pet.requirements.training});
                    
                    /* Setting Affiliate Links / Packages Section */
                    this.setState({essentials : pet.packages.essentials});
                    this.setState({intermediate : pet.packages.intermediate});
                    this.setState({advanced : pet.packages.advanced});
                }
            }
            else{
                this.setState({loggedOut : true});
            }
        }
        this.setState({loading : false});
    }

    updateHypoallergenic = (value) => {
        this.setState({hypoallergenic : value});
    }

    updateCoexist = (value) => {
        this.setState({coexist : value});
    }

    updateHandleable = (value) => {
        this.setState({handleable : value});
    }

    updateSheds = (value) => {
        this.setState({sheds : value});
    }

    updateTravelFriendly = (value) => {
        this.setState({travel_friendly : value});
    }
    // Function to update petName state
    updatePetName = (value) => {
        this.setState({ petName: value });
    };

    // Function to update profilePicture_url state
    updateProfilePicture = (value) => {
        this.setState({ profilePicture: value });
    };

    // Function to update petType state
    updatePetType = (value) => {
        this.setState({ petType: value });
    };

    // Function to update difficultyRating state
    updateDifficulty = (value) => {
        this.setState({ difficulty: value });
    };

    // Function to update dif_time state
    updateDifTime = (value) => {
        this.setState({ dif_time: value });
    };

    // Function to update dif_knowledge state
    updateDifKnowledge = (value) => {
        this.setState({ dif_knowledge: value });
    };

    // Function to update dif_cost state
    updateDifCost = (value) => {
        this.setState({ dif_cost: value });
    };

    // Function to update dif_maintenance state
    updateDifMaintenance = (value) => {
        this.setState({ dif_maintenance: value });
    };

    // Function to update height_range state
    updateHeightRange = (value) => {
        this.setState({ height_range: value });
    };

    // Function to update weight_range state
    updateWeightRange = (value) => {
        this.setState({ weight_range: value });
    };

    // Function to update lifespan_range state
    updateLifespanRange = (value) => {
        this.setState({ lifespan_range: value });
    };

    updateChildFriendly(value) {
        this.setState({child_friendly : value});
    }

    

    // Function to update activity_level state
    updateActivityLevel = (value) => {
        this.setState({ activity_level: value });
    };

    // Function to update affection_level state
    updateAffectionLevel = (value) => {
        this.setState({ affection_level: value });
    };

    // Function to update living_environment state
    updateLivingEnvironment = (value) => {
        this.setState({ living_environment: value });
    };

    // Function to update habitat state
    updateHabitat = (value) => {
        this.setState({ habitat: value });
    };

    // Function to update diet state
    updateDiet = (value) => {
        this.setState({ diet: value });
    };

    // Function to update hygiene state
    updateHygiene = (value) => {
        this.setState({ hygiene: value });
    };

    // Function to update exercise state
    updateExercise = (value) => {
        this.setState({ exercise: value });
    };

    // Function to update vet state
    updateVet = (value) => {
        this.setState({ vet: value });
    };

    // Function to update training state
    updateTraining = (value) => {
        this.setState({ training: value });
    };

    // Function to update personality state
    updatePersonality = (value) => {
        this.setState({ personality: value });
    };

    // Function to update essentials state
    updateEssentials = (value) => {
        this.setState({ essentials: value });
    };

    // Function to update intermediate state
    updateIntermediate = (value) => {
        this.setState({ intermediate: value });
    };

    // Function to update advanced state
    updateAdvanced = (value) => {
        this.setState({ advanced: value });
    };

    render() {
        return (
            <div>
                {this.state.loading?
                    <>

                    </>
                    :
                    <>
                        <h3 className="form-section-name"><b>Overview Information *</b></h3>
                        <div className="form-section">
                            <OverviewSection petName={this.state.petName} 
                                            profilePicture={this.state.profilePicture}
                                            currentProfilePicture={this.state.currentProfilePicture}
                                            petType={this.state.petType}
                                            updatePetName={this.updatePetName}
                                            updateProfilePicture={this.updateProfilePicture}
                                            updatePetType={this.updatePetType}/>
                        </div>
                        <br></br>
                        <h3 className="form-section-name"><b>Difficulty Information *</b></h3>
                        <div className="form-section">
                            <DifficultySection 
                                difficulty={this.state.difficulty}
                                dif_knowledge={this.state.dif_knowledge}
                                dif_time={this.state.dif_time}
                                dif_maintenance={this.state.dif_maintenance}
                                dif_cost={this.state.dif_cost}
                                updateDifficulty={this.updateDifficulty}
                                updateDifTime={this.updateDifTime}
                                updateDifKnowledge={this.updateDifKnowledge}
                                updateDifCost={this.updateDifCost}
                                updateDifMaintenance={this.updateDifMaintenance}
                            />
                        </div>
                        <br></br>
                        <h3 className="form-section-name"><b>Quick Profile *</b></h3>
                        <div className="form-section">
                            <QuickProfileSection 
                                height_range={this.state.height_range}
                                weight_range={this.state.weight_range}
                                lifespan_range={this.state.lifespan_range}
                                child_friendly={this.state.child_friendly}
                                travel_friendly={this.state.travel_friendly}
                                hypoallergenic={this.state.hypoallergenic}
                                coexist={this.state.coexist}
                                handleable={this.state.handleable}
                                sheds={this.state.sheds}
                                activity_level={this.state.activity_level}
                                affection_level={this.state.affection_level}
                                personality={this.state.personality}
                                living_environment={this.state.living_environment}

                                updateHeightRange={this.updateHeightRange}
                                updateWeightRange={this.updateWeightRange}
                                updateLifespanRange={this.updateLifespanRange}
                                updateChildFriendly={this.updateChildFriendly}
                                updateTravelFrendly={this.updateTravelFriendly}
                                updateHypoallergenic={this.updateHypoallergenic}
                                updateCoexist={this.updateCoexist}
                                updateHandleable={this.updateHandleable}
                                updateSheds={this.updateSheds}
                                updateActivityLevel={this.updateActivityLevel}
                                updateAffectionLevel={this.updateAffectionLevel}
                                updatePersonality={this.updatePersonality}
                                updateLivingEnvironment={this.updateLivingEnvironment}
                            />
                        </div>
                        <br></br>
                        <h3 className="form-section-name"><b>Requirements *</b></h3>
                        <div className="form-section">
                            <RequirementsSection 
                                habitat={this.state.habitat}
                                diet={this.state.diet}
                                hygiene={this.state.hygiene}
                                exercise={this.state.exercise}
                                vet={this.state.vet}
                                training={this.state.training}

                                updateHabitat={this.updateHabitat}
                                updateDiet={this.updateDiet}
                                updateHygiene={this.updateHygiene}
                                updateExercise={this.updateExercise}
                                updateVet={this.updateVet}
                                updateTraining={this.updateTraining}
                            />
                        </div>
                        <br></br>
                        <h3 className="form-section-name"><b>Packages *</b></h3>
                        <div className="form-section">
                            <PackagesSection 
                                essentials={this.state.essentials}
                                intermediate={this.state.intermediate}
                                advanced={this.state.advanced}
                                
                                updateEssentials={this.updateEssentials}
                                updateIntermediate={this.updateIntermediate}
                                updateAdvanced={this.updateAdvanced}/>
                        </div>
                        <div className="buttons">
                            <div className="float-left">
                                {this.state.deleteLoading?
                                    <Button variant="danger" disabled={true}><Spinner size="sm"/>Delete</Button>
                                    :
                                    <Button variant="danger" onClick={this.deletePet}>Delete</Button>}
                            </div>
                            <div className="float-right button-group-bottom">
                                <div>
                                    {this.state.saveLoading?
                                        <Button variant='success' disabled={true}><Spinner size="sm"/> Save</Button>
                                        :
                                        <Button variant='success' onClick={this.savePet}>Save</Button>}
                                </div>
                                <div>
                                    {this.state.publishLoading?
                                        <Button variant='dark' disabled={true}><Spinner size="sm"/>Publish</Button>
                                        :
                                        <Button variant='dark' onClick={this.publishPet}>Publish</Button>}
                                </div>
                                <div>
                                    {this.state.takeDownLoading?
                                        <Button variant='dark' disabled={true}><Spinner size="sm"/>Take Down</Button>
                                        :
                                        <Button variant='dark' onClick={this.takeDown}>Take Down</Button>}
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="clear-div"></div>
                <p></p>
            </div>
        )
    }
}

export default EditPetForm;